import React, { useEffect, useState, useCallback } from 'react'
import { GoogleMap } from '@react-google-maps/api';
import { DEFAULT_LAT, DEFAULT_LNG, DEFAULT_ZOOM, EUROPE_CENTER, EUROPE_ZOOM } from "../constants/map";
import { useParams } from '../../node_modules/react-router-dom/index';

const options = {
  disableDefaultUI: true,
  zoomControl: true,
  mapTypeControl: true,
  scaleControl: true,
  mapTypeControlOptions: {
    mapTypeIds: ['roadmap', 'satellite']
  }
};

const mapContainerStyle = {
  height: "100%",
  width: "100%"
};

const defaultCenter = {
  lat: DEFAULT_LAT,
  lng: DEFAULT_LNG,
};

// const defaultCenter = {lat: 41.04431498347576, lng: 16.873920662645865}

export const libraries = ["geometry", "drawing", "visualization", "places"];

export default function MapContainer({ zoom, center, onCLick, children, onZoomChanged, onCenterChanged, maxZoom, isLayerVisible, cyclePaths, color }) {

  let [mapCenter, setMapCenter] = useState(center)
  let [map, setMap] = useState(null)
  let { id } = useParams();
  let isAll = id == "all" ? true : false;
  const [isCyclePathLayerLoaded, setIsCyclePathLayerLoaded] = useState(false);
  const defaultOpacity = 0.4;
  const defaultColor = '#0b28e8';

  useEffect(() => {
    setMapCenter(center);
    if (!map) return;

    //carica il layer la prima volta se l'array è popolato
    if(cyclePaths?.length > 0 && !isCyclePathLayerLoaded){
      Object.values(cyclePaths).forEach(cyclePath => {
        map.data.addGeoJson(cyclePath);
      })
      setIsCyclePathLayerLoaded(true);
    }

    //gestisce la visualizzazione in base al bottone
    map.data.setStyle({
      strokeColor: color?.hex ? color.hex : defaultColor,
      strokeOpacity: color?.alpha ? color.alpha : defaultOpacity,
      strokeWeight: 2,
      zIndex: 1,
      visible: isLayerVisible
    })

  }, [center, isLayerVisible, color])

  const onLoad = useCallback((map) => {
    setMap(map);

    //layer statico
    // const bikeLayer = new window.google.maps.BicyclingLayer();
    // bikeLayer.setMap(map);
  }, [color]);

  return(
      <>
        <GoogleMap
            id="map"
            onLoad={onLoad}
            mapContainerStyle={mapContainerStyle}
            center={isAll ? EUROPE_CENTER : mapCenter || defaultCenter}
            options={{maxZoom, ...options}}
            zoom={isAll ? EUROPE_ZOOM : zoom || DEFAULT_ZOOM}
            onZoomChanged={() => map && onZoomChanged && onZoomChanged(map.getZoom())}
            onCenterChanged={() => map && onCenterChanged && onCenterChanged(map.getCenter())}
            onClick={onCLick}>
          {children}
        </GoogleMap>
      </>
  );
}