import {useQueries, useQuery} from "react-query";
import {
    ACHIEVEMENTS,
    ARTICLES,
    AWARD_POSITIONS,
    AWARDS,
    CATEGORIES,
    CITIES,
    CODES,
    COORDINATES,
    COUPONS,
    CUSTOMFIELDS,
    CYCLE_PATHS,
    get,
    INVITE_FRIENDS,
    MESSAGES,
    OFFICES,
    ORGANIZATIONS,
    PARTIALS,
    POLYLINES,
    RANKINGS,
    RANKS,
    RECORDS,
    RIDES,
    SEATS,
    SESSIONS,
    SETTINGS,
    SHOPS,
    STATISTICS,
    SURVEIES,
    USERS,
    VENDORS
} from "./Client";
import {useEffect, useState} from "react";
import {db} from "../db";
import {useLiveQuery} from "dexie-react-hooks";
import dayjs from "dayjs";

export function useGetSessionsByOrganizationAndFilter(id, json) {
    const { status, data, error } = useQuery([SESSIONS, { id, json }], () => get(SESSIONS,
        { params: { org: id, json: json } }
    ));
    return { status, sessions: data, error };
}

export function useGetRidesByOrganizationAndFilter(id, json) {
    const { status, data, error } = useQuery([RIDES, { id, json }], () => get(RIDES + "/paginated",
        { params: { org: id, json: json } }
    ));
    return { status, rides: data, error };
}

export function useGetVendors(uid) {
    const {status, data, error} = useQuery([VENDORS, {vendor: uid}], () => get(VENDORS, {elem: uid}));
    return {status, vendors: data || [], error};
}

export function useGetShops(uid, enabled) {
    const {
        status,
        data,
        error
    } = useQuery([SHOPS, {vendor: uid}], () => get(VENDORS, {elem: uid + '/' + SHOPS,}), {enabled: enabled || !!uid});
    return {status, shops: data || [], error};
}

export function useGetShopsByOrganization(oid) {
    const {
        status,
        data,
        error
    } = useQuery([SHOPS, {organization: oid}], () => get(`${ORGANIZATIONS}/${SHOPS}`, {params: {oid: oid}}));
    return {status, shops: data || [], error};
}

export function useGetParticipantShopsByOrganization(oid) {
    const {
        status,
        data,
        error
    } = useQuery([SHOPS, {organization: oid}], () => get(`${ORGANIZATIONS}/${oid}/participant-shops`));
    return {status, shops: data || [], error};
}

export function useGetShop(uid, shopId) {
    const {
        status,
        data,
        error
    } = useQuery([SHOPS, {shop: shopId}], () => get(VENDORS, {elem: uid + '/' + SHOPS + '/' + shopId}));
    return {status, shop: data || [], error};
}

export function useGetShopVerified(vid, shopId, enabled = true) {
    const {
        status,
        data,
        error
    } = useQuery([SHOPS, {shop: shopId}], () => get(`${SHOPS}/${shopId}/verify`, {params: {vid}}), {enabled});
    return {status, shop: data || {}, error};
}

export function useGetShopByPublicLink(publicLink, enabled = true) {
    const {
        status,
        data,
        error
    } = useQuery([SHOPS, {publicLink}], () => get(`${SHOPS}/publicLink`, {elem: publicLink}), {enabled});
    return {status, shop: data || {}, error};
}

export function useGetArticles(uid) {
    const {
        status,
        data,
        error
    } = useQuery([ARTICLES, {uid: uid}], () => get(VENDORS, {elem: uid + '/' + ARTICLES}), {enabled: !!uid});
    return {status, articles: data || [], error};
}

export function useGetOfficeArticles(oid) {
    const {
        status,
        data,
        error
    } = useQuery([ARTICLES, {oid: oid}], () => get(ORGANIZATIONS, {elem: oid + '/office-articles'}), {enabled: !!oid});
    return {status, articles: data || [], error};
}

export function useGetArticleCoupons(id) {
    const {
        status,
        data,
        error
    } = useQuery([ARTICLES, {id: id}, COUPONS], () => get(ARTICLES + '/' + id + '/' + COUPONS));
    return {status, coupons: data || [], error};
}

export function useGetVendorCoupons(uid) {
    const {
        status,
        data,
        error
    } = useQuery([VENDORS, {uid: uid}, COUPONS], () => get(VENDORS + '/' + uid + '/' + COUPONS));
    return {status, coupons: data || [], error};
}

export function useGetCategories() {
    const {status, data, error} = useQuery(CATEGORIES, () => get(SHOPS, {elem: CATEGORIES}));
    return {status, categories: data || [], error};
}

export function useGetCoordinates(address) {
    const {
        status,
        data,
        error
    } = useQuery([COORDINATES, address], () => get(VENDORS, {elem: COORDINATES + '/' + address}));
    return {status, coordinates: data || [], error};
}

export function useGetCoupons(organizationId = null) {
    const {
        status,
        data,
        error
    } = useQuery([COUPONS, {organization: organizationId}], () => get(ORGANIZATIONS + '/' + COUPONS, {params: {oid: organizationId}}));
    return {status, coupons: data || [], error};
}

export function useGetSessions() {
    const {status, data, error} = useQuery(SESSIONS, () => get(SESSIONS));
    return {status, sessions: data, error};
}

export function useGetIntervalSessions(start, end) {
    const {status, data, error} = useQuery([SESSIONS, {start, end}], () => get(SESSIONS, {
        params: {
            start,
            end
        }
    }), {enabled: !!start && !!end});
    return {status, sessions: data, error};
}

export function useGetSessionInfo(id, v2=false, partials=true) {
    const {status, data, error} = useQuery([SESSIONS, {id: id}], () => get(SESSIONS, {
        elem: id,
        params: {partials, v2}
    }), {enabled:!!id});
    return {status, session: data, error};
}

export function useGetPartialParameters() {
    const {status, data, error} = useQuery(SESSIONS + "DefaultParameters", () => get(SESSIONS + "/parameters"));
    return {status, defaultParameters: data || [], error};
}

export function useSynchronizeCities() {
    const {status, data, error} = useQuery(CITIES, () => get(CITIES), {staleTime: Infinity});
    const [state, setState] = useState({});

    useEffect(() => {
        db.cities.bulkPut(data)
            .then(() => setState({status, cities: data, error}))
            .catch(e => setState({error: e}))
    }, [status, data, error])
    return state
}

/**
 * restituisce una lista di città
 * @returns {{cities: (*|*[]|Array.<{istatId:int, city:string}>), error: unknown, status: "idle" | "error" | "loading" | "success"}}
 */
export function useGetCities() {
    return useLiveQuery(() => db.cities.toArray()) || []
}

export function useGetCity(istatId) {
    const {
        status,
        data,
        error
    } = useQuery([CITIES, {istatId: istatId}], () => get(CITIES, {elem: istatId}), {staleTime: Infinity});
    return {status, city: data || {}, error};
}

export function useGetOrganizations(enabled = true) {
    const {status, data, error} = useQuery(ORGANIZATIONS, () => get(ORGANIZATIONS), {enabled: enabled});
    return {status, organizations: data || [], error};
}

export function useGetOrganization(id, enabled) {
    const {
        status,
        data,
        error
    } = useQuery([ORGANIZATIONS, {id: id}], () => get(ORGANIZATIONS, {elem: id}), {enabled: enabled});
    return {status, organization: data || {}, error};
}

export function useGetOrganizationOffices(oid) {
    const {
        status,
        data,
        error
    } = useQuery([OFFICES, {oid: oid}], () => get(`${ORGANIZATIONS}/${oid}/offices`), {enabled: !!oid});
    return {status, offices: data || [], error};
}

export function useGetOrganizationCodes(id) {
    const {
        status,
        data,
        error
    } = useQuery([ORGANIZATIONS, {id: id}, CODES], () => get(ORGANIZATIONS, {elem: id + "/" + CODES}));
    return {status, codes: data || [], error};
}

export function useGetOrganizationSeats(id) {
    const {
        status,
        data,
        error
    } = useQuery([ORGANIZATIONS, {id: id}, SEATS], () => get(ORGANIZATIONS, {elem: id + "/" + SEATS}));
    return {status, seats: data || [], error};
}

export function useGetOrganizationManagers(id) {
    const {
        status,
        data,
        error
    } = useQuery([ORGANIZATIONS, {id: id}, USERS], () => get(ORGANIZATIONS, {elem: id + "/" + USERS}));
    return {status, managers: data || [], error};
}

export function useGetOrganizationMessages(id) {
    const {
        status,
        data,
        error
    } = useQuery([ORGANIZATIONS, {id: id}, MESSAGES], () => get(ORGANIZATIONS, {elem: id + "/" + MESSAGES}));
    return {status, messages: data || [], error};
}

export function useGetMessageDetails(id) {
    const {status, data, error} = useQuery([MESSAGES, {id: id}], () => get(MESSAGES, {elem: id}));
    return {status, message: data || [], error};
}

export function useGetOrganizationRanks(id) {
    const {
        status,
        data,
        error
    } = useQuery([ORGANIZATIONS, {id: id}, RANKINGS], () => get(ORGANIZATIONS, {elem: id + "/" + RANKINGS}));
    return {status, ranks: data || [], error};
}

export function useGetOrganizationCustomField(id) {
    const {
        status,
        data,
        error
    } = useQuery([ORGANIZATIONS, {id: id}, CUSTOMFIELDS], () => get(ORGANIZATIONS, {elem: id + "/" + CUSTOMFIELDS}));
    return {status, customField: data || [], error};
}

export function useGetOrganizationAchievements(id) {
    const {
        status,
        data,
        error
    } = useQuery([ORGANIZATIONS, {id: id}, ACHIEVEMENTS], () => get(ORGANIZATIONS, {elem: id + "/" + ACHIEVEMENTS}));
    return {status, achievements: data || [], error};
}

export function useGetOrganizationSettings(id, options = {}) {
    const {
        status,
        data,
        error
    } = useQuery([ORGANIZATIONS, {id: id}, SETTINGS], () => get(ORGANIZATIONS, {elem: id + "/" + SETTINGS}), {enabled: !!id, ...options});
    return {status, settings: data || [], error};
}

export function useGetOrganizationPartials(id, filters, isAll) {
    const {status, data, error} = useQuery([ORGANIZATIONS, {id: id}, PARTIALS, {filter: filters}],
        () => get(ORGANIZATIONS, {elem: id + "/" + PARTIALS, params: filters}),
        { enabled: !isAll }
        // { staleTime: Infinity }
    );
    return {status, partials: data, error};
}

export function useGetOrganizationPartialsAll(id, filters, isAll) {
    const {status, data, error} = useQuery([PARTIALS, {filter: filters}],
        () => get("public/" + PARTIALS, {params: filters}),
        { enabled: !isAll }
        // { staleTime: Infinity }
    );
    return { status, partials: data, error };
}

// !isAll
export function useGetOrganizationPolylines(id, filters, isAll) {
    const {status, data, error} = useQuery([ORGANIZATIONS, {id: id}, POLYLINES, {filter: filters}],
        () => get(ORGANIZATIONS, {elem: id + "/" + POLYLINES, params: filters}),
        { enabled: !isAll }
        // { staleTime: Infinity },
    );
    return {status, polylines: data, error};
}

export function useGetOrganizationPolylinesAll(id, filters, isAll) {
    const {status, data, error} = useQuery([POLYLINES, {filter: filters}],
        () => get("public/" + POLYLINES, {params: filters}),
        // { staleTime: Infinity },
        { enabled: !isAll }
    );
    return { status, polylines: data, error };
}

export function useGetOrganizationRecords(id) {
    const {status, data, error} = useQuery([ORGANIZATIONS, {id: id}, RECORDS],
        () => get(ORGANIZATIONS, {elem: id + "/" + RECORDS}));
    return {status, records: data || [], error};
}

export function useGetOrganizationAwardPositions(id) {
    const {status, data, error} = useQuery([ORGANIZATIONS, {id: id}, AWARD_POSITIONS],
        () => get(ORGANIZATIONS, {elem: id + "/" + AWARD_POSITIONS}));
    return {status, awardPositions: data || [], error};
}

export function useGetNationalRanks() {
    const {status, data, error} = useQuery(["National", RANKS], () => get(RANKINGS, {params: {"national": true}}));
    return {status, ranks: data || [], error};
}

export function useGetNationalAchievements() {
    const {
        status,
        data,
        error
    } = useQuery(["National", ACHIEVEMENTS], () => get(ACHIEVEMENTS, {params: {"national": true}}));
    return {status, achievements: data || [], error};
}

export function useGetRank(id, enabled = true) {
    const {status, data, error} = useQuery([RANKS, id], () => get(RANKINGS, {
        elem: id,
        params: {"withUsers": true}
    }, {enabled}));
    return {status, rank: data || {}, error};
}

export function useGetAchievement(id) {
    const {status, data, error} = useQuery([ACHIEVEMENTS, id], () => get(ACHIEVEMENTS, {elem: id + "/users"}));
    return {status, achievement: data || [], error};
}

export function useGetRankingAwards(rankingId) {
    const {status, data, error} = useQuery([AWARDS, {rid: rankingId}], () => get(`${RANKINGS}/${rankingId}/${AWARDS}`));
    return {status, awards: data || [], error};
}

export function useGetAchievementsAwards(achievementId, enabled = true) {
    const {
        status,
        data,
        error
    } = useQuery([AWARDS, {aid: achievementId}], () => get(`${ACHIEVEMENTS}/${achievementId}/${AWARDS}`), {enabled});
    return {status, awards: data || [], error};
}

export function getTableColumns(key, defaultColumns) {
    let colJson = localStorage.getItem(key);
    if (colJson) {
        let visibles = JSON.parse(colJson)
        let cols = defaultColumns.slice()
        cols.forEach(c => c.hide = (visibles.find(v => v.headerName === c.headerName) || {}).hide)
        return cols
    }
    return defaultColumns
}

export function useGetCustomAwards() {
    const {status, data, error} = useQuery(["AwardsCustom"], () => get(`${AWARDS}/customs`));
    return {status, customAwards: data || [], error};
}

export function useGetSessionsStatistics(oid) {
    const {
        status,
        data,
        error
    } = useQuery(["SessionStatistics", {oid}], () => get(`${STATISTICS}/${ORGANIZATIONS}/${oid}/sessions`), {staleTime: dayjs.duration(1, 'day').asMilliseconds()});//stale per un giorno
    return {status, sessionsStatistics: data || [], error};
}

export function useGetStatistics(oid, startDate, endDate, enableLagQuery = true, isAll) {
    return useQueries(
        [
            {
                queryKey: [STATISTICS, "sessions", oid, startDate, endDate],
                queryFn: () => get(`${STATISTICS}/${ORGANIZATIONS}/${oid}/sessions`, {params: {startDate, endDate}}),
                enabled: !!startDate && !!endDate && !isAll,
                options: {
                    refetchOnWindowFocus: false,
                    refetchOnmount: false,
                    refetchOnReconnect: false,
                    staleTime: dayjs.duration(1, 'day').asMilliseconds()
                }//fresh per un giorno
            },
            {
                queryKey: [STATISTICS, "sessions-type", oid, startDate, endDate],
                queryFn: () => get(`${STATISTICS}/${ORGANIZATIONS}/${oid}/sessions-type`, {
                    params: {
                        startDate,
                        endDate
                    }
                }),
                enabled: !!startDate && !!endDate && !isAll,
                options: {
                    refetchOnWindowFocus: false,
                    refetchOnmount: false,
                    refetchOnReconnect: false,
                    staleTime: dayjs.duration(1, 'day').asMilliseconds()
                }
            },
            {
                queryKey: [STATISTICS, "sessions-volume-hour", oid, startDate, endDate],
                queryFn: () => get(`${STATISTICS}/${ORGANIZATIONS}/${oid}/sessions-volume-hour`, {
                    params: {
                        startDate,
                        endDate
                    }
                }),
                enabled: !!startDate && !!endDate && !isAll,
                options: {
                    refetchOnWindowFocus: false,
                    refetchOnmount: false,
                    refetchOnReconnect: false,
                    staleTime: dayjs.duration(1, 'day').asMilliseconds()
                }
            },
            {
                queryKey: [STATISTICS, "sessions-volume-day", oid, startDate, endDate],
                queryFn: () => get(`${STATISTICS}/${ORGANIZATIONS}/${oid}/sessions-volume-day`, {
                    params: {
                        startDate,
                        endDate
                    }
                }),
                enabled: !!startDate && !!endDate && !isAll,
                options: {
                    refetchOnWindowFocus: false,
                    refetchOnmount: false,
                    refetchOnReconnect: false,
                    staleTime: dayjs.duration(1, 'day').asMilliseconds()
                }
            },
            {
                queryKey: [STATISTICS, "users-volume-age", oid, startDate, endDate],
                queryFn: () => get(`${STATISTICS}/${ORGANIZATIONS}/${oid}/users-volume-age`, {
                    params: {
                        startDate,
                        endDate
                    }
                }),
                enabled: !!startDate && !!endDate && !isAll,
                options: {
                    refetchOnWindowFocus: false,
                    refetchOnmount: false,
                    refetchOnReconnect: false,
                    staleTime: dayjs.duration(1, 'day').asMilliseconds()
                }
            },
            {
                queryKey: [STATISTICS, "users-statistics", oid, startDate, endDate],
                queryFn: () => get(`${STATISTICS}/${ORGANIZATIONS}/${oid}/users-statistics`, {
                    params: {
                        startDate,
                        endDate
                    }
                }),
                enabled: !!startDate && !!endDate && !isAll,
                options: {
                    refetchOnWindowFocus: false,
                    refetchOnmount: false,
                    refetchOnReconnect: false,
                    staleTime: dayjs.duration(1, 'day').asMilliseconds()
                }
            },
            {
                queryKey: [STATISTICS, "legs", oid, startDate, endDate],
                queryFn: () => get(`${STATISTICS}/${ORGANIZATIONS}/${oid}/legs`, {
                    params: {
                        startDate,
                        endDate
                    }
                }),
                enabled:  enableLagQuery && (!!startDate && !!endDate) && !isAll,
                options: {
                    refetchOnWindowFocus: false,
                    refetchOnmount: false,
                    refetchOnReconnect: false,
                    staleTime: dayjs.duration(1, 'day').asMilliseconds()
                }//fresh per un giorno
            },
        ],
    );
}

export function useGetStatisticsForAll(startDate, endDate, enableLagQuery = true, isAll) {
    return useQueries([
        {
            queryKey: [STATISTICS, "sessions", startDate, endDate],
            queryFn: () =>
                get(`public/${STATISTICS}/sessions`, {
                    params: { startDate, endDate },
                }),
            enabled: !!startDate && !!endDate && !isAll,
            options: {
                refetchOnWindowFocus: false,
                refetchOnmount: false,
                refetchOnReconnect: false,
                staleTime: dayjs.duration(1, "day").asMilliseconds(),
            }, //fresh per un giorno
        },
        {
            queryKey: [STATISTICS, "sessions-type", startDate, endDate],
            queryFn: () =>
                get(`public/${STATISTICS}/sessions-type`, {
                    params: {
                        startDate,
                        endDate,
                    },
                }),
            enabled: !!startDate && !!endDate && !isAll,
            options: {
                refetchOnWindowFocus: false,
                refetchOnmount: false,
                refetchOnReconnect: false,
                staleTime: dayjs.duration(1, "day").asMilliseconds(),
            },
        },
        {
            queryKey: [STATISTICS, "sessions-volume-hour", startDate, endDate],
            queryFn: () =>
                get(`public/${STATISTICS}/sessions-volume-hour`, {
                    params: {
                        startDate,
                        endDate,
                    },
                }),
            enabled: !!startDate && !!endDate && !isAll,
            options: {
                refetchOnWindowFocus: false,
                refetchOnmount: false,
                refetchOnReconnect: false,
                staleTime: dayjs.duration(1, "day").asMilliseconds(),
            },
        },
        {
            queryKey: [STATISTICS, "sessions-volume-day", startDate, endDate],
            queryFn: () =>
                get(`public/${STATISTICS}/sessions-volume-day`, {
                    params: {
                        startDate,
                        endDate,
                    },
                }),
            enabled: !!startDate && !!endDate && !isAll,
            options: {
                refetchOnWindowFocus: false,
                refetchOnmount: false,
                refetchOnReconnect: false,
                staleTime: dayjs.duration(1, "day").asMilliseconds(),
            },
        },
        {
            queryKey: [STATISTICS, "users-volume-age", startDate, endDate],
            queryFn: () =>
                get(`public/${STATISTICS}/users-volume-age`, {
                    params: {
                        startDate,
                        endDate,
                    },
                }),
            enabled: !!startDate && !!endDate && !isAll,
            options: {
                refetchOnWindowFocus: false,
                refetchOnmount: false,
                refetchOnReconnect: false,
                staleTime: dayjs.duration(1, "day").asMilliseconds(),
            },
        },
        {
            queryKey: [STATISTICS, "users-statistics", startDate, endDate],
            queryFn: () =>
                get(`public/${STATISTICS}/users-statistics`, {
                    params: {
                        startDate,
                        endDate,
                    },
                }),
            enabled: !!startDate && !!endDate && !isAll,
            options: {
                refetchOnWindowFocus: false,
                refetchOnmount: false,
                refetchOnReconnect: false,
                staleTime: dayjs.duration(1, "day").asMilliseconds(),
            },
        },
        {
            queryKey: [STATISTICS, "legs", startDate, endDate],
            queryFn: () =>
                get(`public/${STATISTICS}/legs`, {
                    params: {
                        startDate,
                        endDate,
                    },
                }),
            enabled: enableLagQuery && !!startDate && !!endDate && !isAll,
            options: {
                refetchOnWindowFocus: false,
                refetchOnmount: false,
                refetchOnReconnect: false,
                staleTime: dayjs.duration(1, "day").asMilliseconds(),
            }, //fresh per un giorno
        },
    ]);
}

export function useGetVendorMessages(uid) {
    const {status, data, error} = useQuery([VENDORS, {uid}, MESSAGES], () => get(`${VENDORS}/${uid}/messages`));
    return {status, messages: data || [], error};
}

export function useGetVendorUnreadMessages(uid) {
    const {
        status,
        data,
        error
    } = useQuery([VENDORS, {uid}, "unreadMessages"], () => get(`${VENDORS}/${uid}/unreadMessages`));
    return {status, messages: data || 0, error};
}

export function useGetArticleVisualizations(uid){
    const {
        status,
        data,
        error
    } = useQuery(["articlesVisualizations", { uid }], () => get(`${VENDORS}/${uid}/articlesVisualizations`));
    return { status, visualizations: data || [], error };
}

export function useGetPlafond(oid) {
    const {
        status,
        data,
        error
    } = useQuery([ORGANIZATIONS, {oid}], () => get(`${ORGANIZATIONS}/${oid}/plafond`));
    return {status, plafond: data || [], error};
}

export function useGetSettings() {
    const {
        status,
        data,
        error
    } = useQuery([SETTINGS], () => get(SETTINGS));
    return {status, settings: data || [], error};
}

export function useGetAllSettings() {
    const {
        status,
        data,
        error
    } = useQuery([ORGANIZATIONS, "settings"], () => get(`${ORGANIZATIONS}/settings`));
    return {status, settings: data || [], error};
}

export function useGetInviteFriends(oid) {
    const {
        status,
        data,
        error
    } = useQuery([INVITE_FRIENDS, {oid}], () => get(`${INVITE_FRIENDS}/${oid}`));
    return {status, inviteFriends: data || [], error};
}

export function useGetSurveies(oid) {
    const {
        status,
        data,
        error
    } = useQuery([SURVEIES, {oid}], () => get(`${SURVEIES}?oid=${oid}`));
    return {status, surveies: data || [], error};
}

export function getCyclePaths(oids){
    return get(`${CYCLE_PATHS}/`, { params: { oids: oids } });
}