import BaseModal from "./BaseModal";
import { TextInputContainer } from "../forms/TextInput";
import { Alert, Grid, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";


export default function DateIntervalModal({ open, interval, onClose, onSubmit, message, min, max, forceLabelOnTop }) {

    const { t } = useTranslation();
    let [values, setValues] = useState({});
    const today = dayjs(new Date()).format("YYYY-MM-DD");

    useEffect(() => {
        setValues({ ...interval })
    }, [interval, open])

    const save = () => {
        onSubmit(values);
        onClose();
    }

    const onTextChange = (value, name) => {
        let newValues = { ...values };
        newValues[name] = value.trim();
        setValues(newValues);
    }

    return <BaseModal open={open} onClose={onClose} onSave={save}>
        <Grid container spacing={4} style={{ margin: 0, width: "100%" }}>
            {message &&
                <Grid xs={12} sx={{ marginBottom: "0.5rem", p:2 }}>
                    <Alert variant="outlined" severity="info" >{message}</Alert>
                </Grid>
            }

            <TextInputContainer xs={12} required label={t('startDate')} value={values.start}
                type="date" max={values.end} min={min}
                onTextChange={(value) => onTextChange(value, "start")} InputLabelProps={{shrink : forceLabelOnTop || true}} />

            <TextInputContainer xs={12} required label={t('endDate')} value={values.end}
                type="date" max={max || today} min={values.start}
                onTextChange={(value) => onTextChange(value, "end")} InputLabelProps={{shrink : forceLabelOnTop || true}} />

        </Grid>
    </BaseModal>
}