import { geoFireDrinkingFountains, geoFirePublicUtilityRecord, geoFireReport } from "../firebase";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { DEFAULT_LAT, DEFAULT_LNG, DEFAULT_ZOOM } from "../constants/map";
import MapContainer from "../components/MapContainer";
import { getRadius, initGeoQuery, useGeoQuery } from "../services/MapManager";
import { Circle, Marker } from "@react-google-maps/api";
import { useResizeEffect } from "../services/useBreakpoint";
import Box from "@mui/material/Box";
import { partialType } from "../constants/partialType";
import pinRed from "../images/icons/pin-red.svg";
import pinBlue from "../images/icons/pin-blue.svg";
import pinGray from "../images/icons/pin-gray.svg";
import BaseModal from "../components/modals/BaseModal";
import { Grid, Typography } from "@mui/material";
import { TextItem } from "../components/TextItem";
import RenderUserRedirect from "../components/cellRender/RenderUserRedirect";
import { unixToString } from "../services/helper";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import StartIconButton from "../components/buttons/StartIconButton";
import RedoIcon from "@mui/icons-material/Redo";
import FormBuilder from "../components/FormBuilder";
import { FORM_TYPE } from "../constants/FormBuilder";
import useGlobalStyles from "../services/useGlobalStyles";
import HoverableIconButton from "../components/hoverables/HoverableIconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import ConfirmIconButton from "../components/buttons/ConfirmIconButton";
import EditFountainModal from "../components/modals/EditFountainModal";
import { debounce } from "lodash";
import { markerType } from "../constants/markerType";
import { PublicUtilityRecordModal, ReportModal } from "../components/modals/PublicUtilityRecordModal";
import ReportList from "./Dashboard/ReportList";
import { USERS, get } from "services/Client";

export default function DrinkingFountains({
    children,
    defaultCenter = { lat: DEFAULT_LAT, lng: DEFAULT_LNG },
    defaultZoom = DEFAULT_ZOOM,
    markersToShow = markerType.DRINKINGFOUNTAINS,
    oid, disabled,
    isLayerVisible,
    cyclePaths,
    color
}) {
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    const ref = useRef();
    const [reports, setReports] = useState(new Map());
    const updateReports = (newReports) => {
        newReports.forEach(r => {
            get(USERS, { elem: r.createdBy }).then((user) => {
                r.email = user.email
                r.username = user.username
            });
        });
        setReports(newReports);
    };
    const [dim, setDim] = useState({ width: 1, height: 1 });
    const [report, setReport] = useState({});
    const [center, setCenter] = useState(defaultCenter);
    const [queryCriteria, setQueryCriteria] = useState({
        center: [defaultCenter.lat, defaultCenter.lng],
        zoom: defaultZoom
    });
    const [geoFire, setGeoFire] = useState(getGeoFire());

    const [markers, setMarkers] = useState([]);

    function getGeoFire() {
        if (markersToShow === markerType.PARKING || markersToShow === markerType.REPAIRING_STATION)
            return geoFirePublicUtilityRecord;
        if (markersToShow === markerType.DRINKINGFOUNTAINS)
            return geoFireDrinkingFountains;
        if (markersToShow === markerType.REPORT)
            return geoFireReport;
        return null
    }

    function radius() {
        return getRadius(queryCriteria.center, queryCriteria.zoom, dim.width, dim.height);
    }

    const getMarkerList = (reports) => Array.from(reports.values()).map(
        (r) => {
            if (markersToShow === markerType.PARKING && r.type === 2)//togliere dalla lista le ciclofficine
                return;
            if (markersToShow === markerType.REPAIRING_STATION && r.type === 1)//togliere dalla lista i parking
                return;
            return <Marker
                position={{ lat: r.l[0], lng: r.l[1] }}
                key={r.key} icon={getIcon(r)}
                onClick={() => setReport({ ...r })}
            />
        }
    )

    const geoQuery = useGeoQuery({
        center: queryCriteria.center,
        radius: radius(),
    }, [dim], geoFire);

    const handleUpdate = useCallback(
        debounce((f) => {
            setMarkers(
                getMarkerList(reports)
            )
        }, 100),//aggiornamento non immediato dello stato
        [geoQuery]
    );

    const handleUpdateImmediate = (reports) => setMarkers(getMarkerList(reports));

    useEffect(() => {
        updateReports(new Map());
        handleUpdateImmediate(new Map());
        setGeoFire(getGeoFire());
    }, [getGeoFire()]);

    useEffect(() => {
        handleUpdateImmediate(reports);
    }, [markersToShow])

    useEffect(() => {
        geoQuery.on("key_entered", function (key, location, distance) {
            if (reports.has(key)) {
                return;
            }
            geoFire.ref().child(key).get().then((snapshot) => {
                if (snapshot.exists()) {
                    if (markersToShow === markerType.REPORT && snapshot.val().orgUid + '' !== '' + oid)//i report sono riferiti ad un'organizzazione specifica
                        return;

                    if (disabled && snapshot.val().deleted)//non mostrare quelle eliminate
                        return;

                    updateReports(reports.set(snapshot.key, { ...snapshot.val(), key: snapshot.key }));
                    handleUpdate(reports);
                } else {
                    console.log("No data available");
                }
            }).catch((error) => {
                console.error(error);
            });
        });

        geoQuery.on("key_exited", function (key, location, distance) {
            if (reports.has(key)) {
                reports.delete(key);
                updateReports(reports);
                handleUpdate(reports);
            }
        });

        // return () => {
        //     // Cancel all of the query's callbacks
        //     geoQuery.cancel();
        // };
    }, [geoQuery]);

    useResizeEffect(() => {
        if (ref.current) {
            setDim({ width: ref.current.clientWidth, height: ref.current.clientHeight });
        }
    });

    const onRestore = (params) => {
        enqueueSnackbar(t('saving...'), { variant: "info" });
        let newFountain = {
            deleted: false,
            deletedAt: null,
            deletedBy: null,
        };
        editFirebaseData(newFountain, params)
    }

    const onEditStatus = (params) => {
        enqueueSnackbar(t('saving...'), { variant: "info" });
        let newFountain = {
            deleted: params.deleted,
        };
        editFirebaseData(newFountain, params)
    }

    function editFirebaseData(newFountain, params) {
        geoFire.ref().child(params.key).update(newFountain).then(() => {
            updateReports(fountains => {
                let f = fountains.set(params.key, { ...params, ...newFountain });
                handleUpdateImmediate(f);
                return f;
            });
            enqueueSnackbar(t('saved'), { variant: "success" });
        }).catch(() => enqueueSnackbar(t('errorSavingData'), { variant: "error" }));
    }

    const onDelete = (params) => {
        enqueueSnackbar(t('saving...'), { variant: "info" });
        geoFire.ref().update({
            [params.key]: null,
        }).then(() => {
            reports.delete(params.key);
            updateReports(reports);
            handleUpdateImmediate(reports);
            enqueueSnackbar(t('saved'), { variant: "success" });
        }).catch(() => enqueueSnackbar(t('errorSavingData'), { variant: "error" }));
    }

    function getIcon(f) {
        //let icon = {url: pinGray, scaledSize: new window.google.maps.Size(35, 48)}
        // if (f.uid) {
        //     icon = {url: pinBlue, scaledSize: new window.google.maps.Size(35, 48)}
        // }
        let icon = { url: pinBlue, scaledSize: new window.google.maps.Size(35, 48) }
        if (f.deleted) {//se eliminato mostro PIN rosso
            icon = { url: pinRed, scaledSize: new window.google.maps.Size(35, 48) }
        }
        return icon;
    }

    const autocompleteAddressField = [{
        key: 'addressInput',
        label: t('address'),
        onCoordinatesFetched: (latLng) => {
            updateReports(new Map());
            setCenter(latLng);
        },
        xs: 12, md: 12,
        noGlobalClasses: true,
        type: FORM_TYPE.addressAutocomplete
    }]

    return (
        <>
            <Box
                sx={{
                    position: 'relative',
                    width: '100%',
                    height: '70vh',
                    '& .gmnoprint': {
                        top: 'auto !important',
                        bottom: 0
                    }
                }}
                ref={ref}
            >
                <Box sx={{
                    top: 0,
                    width: '100%',
                    zIndex: 100,
                    position: 'absolute',
                    padding: 2,
                    paddingX: 8,
                    '& .MuiOutlinedInput-root': {
                        backgroundColor: 'white'
                    }
                }}>
                    <FormBuilder fields={autocompleteAddressField} />
                </Box>
                <MapContainer
                    zoom={defaultZoom}
                    center={center}
                    onZoomChanged={(z) => setQueryCriteria((q) => ({ ...q, zoom: z }))}
                    onCenterChanged={(c) => setQueryCriteria((q) => ({ ...q, center: [c.lat(), c.lng()] }))}
                    isLayerVisible={isLayerVisible}
                    cyclePaths={cyclePaths}
                    color={color}
                >
                    {
                        markers
                    }
                    {
                        children
                    }

                    {/*Mostra l'aria di interesse di cariacmento dei marker*/}
                    {/*<Circle*/}
                    {/*    center={{*/}
                    {/*        lat: geoQuery.center()[0],*/}
                    {/*        lng: geoQuery.center()[1]*/}
                    {/*    }}*/}
                    {/*    radius={geoQuery.radius() * 1000}*/}
                    {/*/>*/}
                </MapContainer>
            </Box>
            <Box sx={{ mt: 4, width: '100%', }}>
                {!disabled && <ReportList reports={Array.from(reports.values())} onReportClick={r => setReport(r)} />}
            </Box>

            {
                markersToShow === markerType.DRINKINGFOUNTAINS &&
                (!disabled && <EditFountainModal fountain={report} onClose={() => setReport({})} open={!!report.key}
                    onDelete={onDelete} onRestore={onRestore} />)
            }
            {
                (markersToShow === markerType.PARKING || markersToShow === markerType.REPAIRING_STATION) &&
                <PublicUtilityRecordModal disabled={disabled} record={report} onClose={() => setReport({})} open={!!report.key}
                    onDelete={onDelete} onRestore={onRestore} onEditStatus={onEditStatus} />
            }
            {
                markersToShow === markerType.REPORT &&
                <ReportModal disabled={disabled} record={report} onClose={() => setReport({})} open={!!report.key}
                    onDelete={onDelete} onRestore={onRestore} onEditStatus={onEditStatus} />
            }
        </>
    );

}
